<template>
  <div class="flex-1">
    <div
      class="border-b"
      :class="
        separator({
          style: data.borderStyle,
          color: data.color?.name
        })
      "
      data-testid="separator-line"
      :style="{
        borderBottomWidth: `${data.width}px`
      }"
    />
  </div>
</template>

<script lang="ts" setup>
import type { StrapiSectionsSeparator } from '@autobid/strapi-integration/typescript/strapi/sections/SectionsSeparator'
import { separator } from '@autobid/ui/utils/cva/separatorCva'

interface Props {
  data: Pick<StrapiSectionsSeparator, 'width' | 'borderStyle' | 'color'>
}

defineProps<Props>()
</script>
